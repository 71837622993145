import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import KorisnikLogo from '../../assets/images/dashboard/korisnik.svg'
import DodjelaTenderaLogo from '../../assets/images/dashboard/dodjela-tendera.svg'
import IzvjestajiLogo from '../../assets/images/dashboard/izvjestaji.svg'
import PrevozniciLogo from '../../assets/images/dashboard/prevoznici.svg'
import TenderiLogo from '../../assets/images/dashboard/tenderi.svg'
import TerminiLogo from '../../assets/images/dashboard/termini.svg'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const CardsContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
}))

const DashboardCard = styled('div')(() => ({
  backgroundColor: 'white',
  width: '340px',
  height: '270px',
  margin: '10px',
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
}))

const CardText = styled('h1')(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.shade.main,
}))

const Logo = styled('img')(() => ({
  width: 100,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '50px',
}))

export default function Dashboard() {
  const navigate = useNavigate()
  const dashboardCards = [
    {
      text: 'Korisnici',
      icon: KorisnikLogo,
      path: '/korisnici',
    },
    {
      text: 'Tenderi',
      icon: TenderiLogo,
      path: '/tenderi',
    },
    {
      text: 'Termini',
      icon: TerminiLogo,
      path: '/termini',
    },
    {
      text: 'Dodjela tendera',
      icon: DodjelaTenderaLogo,
      path: '/dodjela-tendera',
    },
    {
      text: 'Prevoznici',
      icon: PrevozniciLogo,
      path: '/prevoznici',
    },
    {
      text: 'Izvještaji',
      icon: IzvjestajiLogo,
      path: '/izvjestaji',
    },
  ]

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <CardsContainer>
        {dashboardCards.map((item) => (
          <DashboardCard key={item.text} onClick={() => navigate(item.path)}>
            <Logo src={item.icon} />
            <CardText>{item.text}</CardText>
          </DashboardCard>
        ))}
      </CardsContainer>
    </>
  )
}
