import React from 'react'
import { useFormik } from 'formik'
import { Button, TextField } from '@mui/material'
import * as Yup from 'yup'

interface FormValues {
  name: string
  email: string
}

const initialValues: FormValues = {
  name: '',
  email: '',
}

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  email: Yup.string().email('Invalid email format').required('This field is required'),
})

export default function PrimjerForma() {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <TextField
          label='Name'
          id='name'
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </div>

      <div>
        <TextField
          label='Email'
          id='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </div>

      <Button type='submit' variant='contained' color='primary'>
        Submit
      </Button>
    </form>
  )
}
