import React from 'react'
import MuiThemeWrapper from './MuiThemeWrapper'
import Login from './app/pages/login/login'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import Dashboard from './app/pages/dashboard-page/dashboardPage'
import Korisnici from './app/pages/korisnici/korisnici'
import NoviKorisnik from './app/pages/korisnici/novi-korisnik'
import PrimjerForma from './app/pages/primjer-forma/primjer-forma'
import Sidebar from './app/components/sidebar/sidebar'
import Tenderi from './app/pages/tenderi/tenderi'

const SidebarLayout = () => (
  <div style={{ display: 'flex' }}>
    <Sidebar />
    <div style={{width:'1600px', padding: '20px'}}>
      <Outlet />
    </div>
  </div>
)

function App() {
  return (
    <MuiThemeWrapper>
      <Router>
        <Routes>
          <Route element={<SidebarLayout />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/korisnici' element={<Korisnici />} />
            <Route path='/korisnici/korisnik' element={<NoviKorisnik />} />
            <Route path='/primjer-forma' element={<PrimjerForma />} />
            <Route path='/tenderi' element={<Tenderi />} />
          </Route>
          <Route path='/login' element={<Login />} />
        </Routes>
      </Router>
    </MuiThemeWrapper>
  )
}

export default App
