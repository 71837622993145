/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export default function StyledDropDown(props:any) {

  return (
    <Select
      sx={{
        '&.MuiOutlinedInput-root': {
          borderRadius: 3,
          height: '45px',
          width: '360px',
        },
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.40)',
        },
      }}
      {...props}
    >
      {props.data?.map((option: any) => (
        <MenuItem key={option.id} value={option.id}>
          {option.ime}
        </MenuItem>
      ))}
    </Select>
  )
}
