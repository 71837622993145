import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../theme/Alumina'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))
const SearchField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function Tenderi() {
  const data = [
    {
      '#': '1',
      'Naziv tendera': 'Tender 1',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '2',
      'Naziv tendera': 'Tender 2',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '3',
      'Naziv tendera': 'Tender 3',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '4',
      'Naziv tendera': 'Tender 4',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '5',
      'Naziv tendera': 'Tender 5',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '6',
      'Naziv tendera': 'Tender 6',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '7',
      'Naziv tendera': 'Tender 7',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '8',
      'Naziv tendera': 'Tender 8',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '9',
      'Naziv tendera': 'Tender 9',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '10',
      'Naziv tendera': 'Tender 10',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '11',
      'Naziv tendera': 'Tender 11',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '12',
      'Naziv tendera': 'Tender 12',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
    {
      '#': '13',
      'Naziv tendera': 'Tender 13',
      Ruta: 'Zvornik - Osjek',
      'Vazenje konkursa': '01.5.2023. - 15.05.2023.',
      Trajanje: '01.06.2023. - 30.11.2023.',
      'Broj ruta': '180',
      Status: 'U toku',
    },
  ]

  const [page, setPage] = React.useState(0)
  const [rowsPerPage] = React.useState(10)

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPage(newPage)
  }

  const theme = useTheme()

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(data.length / rowsPerPage) - 1)
    setPage(lastPage)
  }

  const handleJumpToFirstPage = () => {
    setPage(0)
  }
  return (
    <>
      <PageTitle>Tenderi</PageTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <Stack spacing={2} width={500}>
                    <label style={{ color: 'gray' }}>
                      Pretraži
                      <Autocomplete
                        disablePortal
                        id='Naziv'
                        disableClearable
                        options={data.map((option) => option['Naziv tendera'])}
                        renderInput={(params) => (
                          <SearchField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </label>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Button variant='contained' color='primary'>
                      Kreiraj novi
                    </Button>
                    <Button variant='outlined' color='primary' style={{ width: '140px' }}>
                      Otkaži
                    </Button>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellTitle align='center'>#</TableCellTitle>
              <TableCellTitle align='center'>Naziv tendera</TableCellTitle>
              <TableCellTitle align='center'>Ruta</TableCellTitle>
              <TableCellTitle align='center'>Vazenje konkursa</TableCellTitle>
              <TableCellTitle align='center'>Trajanje</TableCellTitle>
              <TableCellTitle align='center'>Broj ruta</TableCellTitle>
              <TableCellTitle align='center'>Status</TableCellTitle>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                }}
              >
                <TableCell component='th' scope='row' align='center'>
                  {row['#']}
                </TableCell>
                <TableCell align='center'>{row['Naziv tendera']}</TableCell>
                <TableCell align='center'>{row['Ruta']}</TableCell>
                <TableCell align='center'>{row['Vazenje konkursa']}</TableCell>
                <TableCell align='center'>{row['Trajanje']}</TableCell>
                <TableCell align='center'>{row['Broj ruta']}</TableCell>
                <TableCell align='center'>{row['Status']}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={data.length}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={({ count, onPageChange }) => (
                  <>
                    <PaginationContent>
                      <IconButton onClick={handleJumpToFirstPage} disabled={page === 0}>
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, page - 1)}
                        disabled={page === 0}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, page + 1)}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </IconButton>

                      <IconButton
                        onClick={handleJumpToLastPage}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                      >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                      </IconButton>
                    </PaginationContent>
                  </>
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}
