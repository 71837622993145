import React, { useState } from 'react'
import {
  Box,
  Drawer,
  List,
  ListItemText,
  Typography,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import aluminaLogo from '../../assets/images/alumina-logo.svg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'

import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    height: '100vh',
    width: 320,
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '10px',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: 10,
  borderRadius: 8,
  color: '#A0AEC0',
  cursor: 'pointer',
  '& .MuiListItemText-root': {
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0.4,
      fontSize: 15,
    },
  },
  '& .MuiTouchRipple-root:active': {
    background: 'transparent !important',
  },
  '& .MuiBox-root': {
    padding: '3px 6px',
    height: 38,
    width: 38,
    marginRight: 8,
    borderRadius: 10,
    '& .MuiSvgIcon-root': {
      marginTop: 4,
      '& path': {
        color: theme.palette.primary.main,
      },
    },
  },
  '&:hover': {
    color: '#000000',
    background: 'transparent',
    '&.Mui-selected': {
      background: 'transparent',
    },
    '& .MuiBox-root': {
      background: theme.palette.primary.main,
      textAlign: 'center',
      '& .MuiSvgIcon-root': {
        '& path': {
          color: '#ffffff',
        },
      },
    },
  },
}))

export default function Sidebar() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const firstSection = [
    {
      id: 1,
      text: 'Dashboard',
      icon: (
        <Box>
          {' '}
          <HomeOutlinedIcon />{' '}
        </Box>
      ),
      path: '/',
    },
    {
      id: 2,
      text: 'Korisnici',
      icon: (
        <Box>
          {' '}
          <PersonOutlineOutlinedIcon />{' '}
        </Box>
      ),
      path: '/korisnici',
    },
  ]

  const secondSection = [
    {
      id: 3,
      text: 'Tenderi',
      icon: (
        <Box>
          {' '}
          <WorkOutlineOutlinedIcon />{' '}
        </Box>
      ),
      path: '/tenderi',
    },
    {
      id: 4,
      text: 'Termini',
      icon: (
        <Box>
          {' '}
          <WatchLaterOutlinedIcon />{' '}
        </Box>
      ),
      path: '',
    },
    {
      id: 5,
      text: 'Dodjela tendera',
      icon: (
        <Box>
          {' '}
          <DescriptionOutlinedIcon />{' '}
        </Box>
      ),
      path: '',
    },
  ]

  const thirdSection = [
    {
      id: 6,
      text: 'Prevoznici',
      icon: (
        <Box>
          {' '}
          <LocalShippingOutlinedIcon />{' '}
        </Box>
      ),
      path: '',
    },
    {
      id: 7,
      text: 'Izvještaji',
      icon: (
        <Box>
          {' '}
          <SignalCellularAltOutlinedIcon />{' '}
        </Box>
      ),
      path: '',
    },
    {
      id: 8,
      text: 'Exit',
      icon: (
        <Box>
          {' '}
          <ExitToAppOutlinedIcon />{' '}
        </Box>
      ),
      path: '/',
    },
  ]

  const drawer = (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{
          width: 1,
          textAlign: 'center',
        }}
      >
        <img src={aluminaLogo} alt='logo' width='130px' height='50px' />
      </Box>
      <List sx={{ mt: 6, padding: '0px 40px' }}>
        {firstSection.map((item) => (
          <StyledListItem key={item.id} onClick={() => navigate(item.path)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </StyledListItem>
        ))}
        <Box>
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              mt: 3,
              mb: 2,
            }}
          >
            TENDERI
          </Typography>
          {secondSection.map((item) => (
            <StyledListItem key={item.id} onClick={() => navigate(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          ))}
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              mt: 3,
              mb: 2,
            }}
          >
            DODATNE OPCIJE
          </Typography>
          {thirdSection.map((item) => (
            <StyledListItem key={item.id} onClick={() => navigate(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          ))}
        </Box>
      </List>
    </Box>
  )

  return (
    <div style={{ display: 'flex' }}>
      <Box>
        <StyledDrawer
          variant='temporary'
          transitionDuration={500}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 320 },
          }}
        >
          {drawer}
        </StyledDrawer>
        <StyledDrawer
          variant='permanent'
          transitionDuration={500}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
          }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </div>
  )
}
