import React from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

export default function Korisnici() {
  return (
    <>
      <PageTitle>Korisnici</PageTitle>
      <Link to='/korisnici/korisnik'>Novi</Link>
      <>tabela</>
    </>
  )
}
