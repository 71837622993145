import React from 'react'
import Grid from '@mui/material/Grid'
import { Box, Button, InputLabel } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { StyledTextField } from '../../components/textField/textField'
import { StyledSwitch } from '../../components/switch/switch'
import backgroundImage from '../../assets/images/alumina-login-bg.jpg'
import aluminaLogo from '../../assets/images/alumina-logo.svg'
import { useNavigate } from 'react-router-dom'

interface FormValues {
  email: string
  password: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('This field is required'),
  password: Yup.string().required('This field is required'),
})

export default function Login() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values)
      navigate('/')
    },
  })

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
        }}
      />
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: { md: 6, lg: 8 } }}>
        <img src={aluminaLogo} alt='logo' width='150px' height='50px' />

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <InputLabel>Korisnik</InputLabel>
            <StyledTextField
              id='email'
              name='email'
              size='small'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <InputLabel>Lozinka</InputLabel>
            <StyledTextField
              id='password'
              type='password'
              name='password'
              size='small'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 3.5,
            }}
          >
            <StyledSwitch />
            <InputLabel sx={{ ml: 1 }}>Zapamti me</InputLabel>
          </Box>
          <Button type='submit' variant='contained' style={{ width: '45%', marginTop: '5%' }}>
            Prijava
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}
