import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { StyledTextField } from '../../components/textField/textField'
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import StyledDropDown from '../../components/dropDown/dropdown'
import StyledModal from '../../components/modal/modal'
import { DropdownErrorText } from '../../components/dropdownErrorText/dropdownErrorText'
import { useNavigate } from 'react-router-dom'

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  marginTop: '50px',
  borderRadius: 12,
  padding: '50px',
}))

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  gap: '30px',
}))

interface FormValues {
  korisnickoIme: string
  tipKorisnika: number
  ime: string
  prezime: string
  kompanija: number
  radnoMjesto: string
  telefon: string
  email: string
  status: number
}

const initialValues: FormValues = {
  korisnickoIme: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  tipKorisnika: '',
  ime: '',
  prezime: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  kompanija: '',
  radnoMjesto: '',
  telefon: '',
  email: '',
  status: 1,
}

const validationSchema = Yup.object({
  korisnickoIme: Yup.string().required('Polje je obavezno!'),
  tipKorisnika: Yup.string().required('Polje je obavezno!'),
  ime: Yup.string().required('Polje je obavezno!'),
  prezime: Yup.string().required('Polje je obavezno!'),
  kompanija: Yup.string().required('Polje je obavezno!'),
  radnoMjesto: Yup.string().required('Polje je obavezno!'),
  telefon: Yup.number().required('Polje je obavezno!'),
  email: Yup.string().email('Neispravna email adresa').required('Polje je obavezno!'),
})

const dropdownOptions = [
  { id: '1', ime: 'Alumina1' },
  { id: '2', ime: 'Alumina2' },
  { id: '3', ime: 'Alumina3' },
]

export default function NoviKorisnik() {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })
  return (
    <>
      <PageTitle>Korisnici {'>'} Detalji</PageTitle>
      <Content>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={5}>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                <span>
                  <InputLabel>Korisnicko ime</InputLabel>
                  <StyledTextField
                    id='korisnickoIme'
                    name='korisnickoIme'
                    size='small'
                    autoComplete='off'
                    value={formik.values.korisnickoIme}
                    onChange={formik.handleChange}
                    error={formik.touched.korisnickoIme && Boolean(formik.errors.korisnickoIme)}
                    helperText={formik.touched.korisnickoIme && formik.errors.korisnickoIme}
                  />
                </span>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Tip korisnika</InputLabel>

                <StyledDropDown
                  data={dropdownOptions}
                  id='tipKorisnika'
                  name='tipKorisnika'
                  size='small'
                  value={formik.values.tipKorisnika}
                  onChange={formik.handleChange}
                  error={formik.touched.tipKorisnika && Boolean(formik.errors.tipKorisnika)}
                />
                <DropdownErrorText>
                  {formik.touched.tipKorisnika && formik.errors.tipKorisnika}
                </DropdownErrorText>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                <span>
                  <InputLabel>Ime</InputLabel>
                  <StyledTextField
                    id='ime'
                    name='ime'
                    size='small'
                    autoComplete='off'
                    value={formik.values.ime}
                    onChange={formik.handleChange}
                    error={formik.touched.ime && Boolean(formik.errors.ime)}
                    helperText={formik.touched.ime && formik.errors.ime}
                  />
                </span>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Prezime</InputLabel>
                <StyledTextField
                  id='prezime'
                  name='prezime'
                  size='small'
                  autoComplete='off'
                  value={formik.values.prezime}
                  onChange={formik.handleChange}
                  error={formik.touched.prezime && Boolean(formik.errors.prezime)}
                  helperText={formik.touched.prezime && formik.errors.prezime}
                />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                <span>
                  <InputLabel>Kompanija</InputLabel>
                  <StyledDropDown
                    data={dropdownOptions}
                    id='kompanija'
                    name='kompanija'
                    size='small'
                    value={formik.values.kompanija}
                    onChange={formik.handleChange}
                    error={formik.touched.kompanija && Boolean(formik.errors.kompanija)}
                  />
                  <DropdownErrorText>
                    {formik.touched.kompanija && formik.errors.kompanija}
                  </DropdownErrorText>
                </span>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Radno mjesto</InputLabel>
                <StyledTextField
                  id='radnoMjesto'
                  name='radnoMjesto'
                  size='small'
                  autoComplete='off'
                  value={formik.values.radnoMjesto}
                  onChange={formik.handleChange}
                  error={formik.touched.radnoMjesto && Boolean(formik.errors.radnoMjesto)}
                  helperText={formik.touched.radnoMjesto && formik.errors.radnoMjesto}
                />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                <span>
                  <InputLabel>Telefon</InputLabel>
                  <StyledTextField
                    id='telefon'
                    name='telefon'
                    size='small'
                    type='number'
                    autoComplete='off'
                    value={formik.values.telefon}
                    onChange={formik.handleChange}
                    error={formik.touched.telefon && Boolean(formik.errors.telefon)}
                    helperText={formik.touched.telefon && formik.errors.telefon}
                  />
                </span>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Email</InputLabel>
                <StyledTextField
                  id='email'
                  name='email'
                  size='small'
                  autoComplete='off'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'end', paddingRight: '140px' }}
              >
                <div>
                  <FormLabel id='demo-row-radio-buttons-group-label'>Status</FormLabel>
                  <RadioGroup
                    row
                    id='status'
                    name='status'
                    value={formik.values.status}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value={1} control={<Radio />} label='Aktivan' />
                    <FormControlLabel value={2} control={<Radio />} label='Neaktivan' />
                  </RadioGroup>
                </div>
              </Grid>
              <Grid item xs={6}>
                <ButtonContainer>
                  <Button
                    type='submit'
                    variant='contained'
                    size='large'
                    style={{ width: '165px' }}
                    onClick={handleModalOpen}
                  >
                    Snimi
                  </Button>
                  <Button
                    variant='outlined'
                    size='large'
                    style={{ width: '165px' }}
                    onClick={() => navigate(-1)}
                  >
                    Otkazi
                  </Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Content>
      <StyledModal open={modalOpen} onClose={handleModalClose} />
    </>
  )
}
