import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(() => ({
    width: 360,
    '& .MuiInputBase-root': {
        padding: 3,
        borderRadius: 10
    },
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.40)'
    }
}));